.Header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: #0D0D0D;
  padding: 1rem 0;
  border-bottom: 3px solid #595959;
}

.Logo {
  width: 50px;
  /* margin: 1rem 0; */
  transition: ease-in-out 400ms all;
}

.Logo:hover {
  transform: rotate(360deg);
}

.Nav {
  /* margin-top: .5rem; */
  display: flex;
  gap: 1rem;
}

.NavLink {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
}

.NavLink:hover {
  border-bottom: 3px solid white;
}

.NavLinkActive {
  border-bottom: 3px solid #962D3E;
}