*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  color: #F3F3F3;
  background-color: #262626;
}

h1,
h2, 
h3, 
h4, 
h5, 
h6 {
  color: #962D3E;
  font-family: "Bungee", cursive;
  margin: 0;
  padding: 0;
}

a {
  color: #348899;
  text-decoration: none;
  font-weight: bold;
}