.Layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Main {
  flex-grow: 1;
  padding: 1rem 2rem;
}